.party {
    .swiper-slide {
        width: 80px !important;
    }
    
    @media only screen and (max-width: 480px) {
        .swiper-slide {
            width: 55px !important;
        }
    }
    
    @media only screen and (min-width: 481px) and (max-width: 1028px) {
        .swiper-slide {
            width: 85px !important;
        }
    }
}


.chakra-avatar {
    --avatar-background: #E2E8F0
}
.chakra-avatar__svg {
    display: none;
}

.chakra-checkbox__control {
    position: relative;
    top: 3px;
}

body {
    background: #CED2E0 url("../../public/lines.svg") center center / cover no-repeat !important;
}

body.dark-mode {
    background: url("../../public/lines_dark.svg") center center / cover no-repeat, linear-gradient(63.18deg, #252731 14.58%, #505776 91.65%) fixed !important;
}

body.grey-mode {
    background: url("../../public/lines_dark.svg") center center / cover no-repeat, #505776 !important;
}

.chat-header {
    display: none !important;
}
.hbf-bot-div {
    margin: auto
}

.webchat__send-icon {
    fill: #F97330 !important
}

#botChat {
    height: 65vh !important;
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.4) !important;
}

#botChat-6481e353fedd4f7668c9f066 {
    height: 65vh !important;
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.4) !important;
}

#botChat-6481d7d9fedd4f7668c9f041 {
    height: 65vh !important;
    box-shadow: 0px 10px 100px rgba(0, 0, 0, 0.4) !important;
}

.chat-footer {
    display: none;
}

.webchat__suggested-action__button {
    color: #F97330 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #F97330 !important;
    border-radius: 6px !important;
}

.webchat__suggested-action__text {
    font-weight: 700 !important;
}

html, body, #app, #app>div {
    height: 100%
  }

.hidden {
    display: none !important;
}

.maintenance-message a {
    text-decoration: underline;
}
.maintenance-message a:hover {
    text-decoration: none;
}